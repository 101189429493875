<template>
	<div class="distributor-invite d-flex flex-column">
		<v-row no-gutters dense class="d-flex flex-grow-0">
			<v-col cols="12" class="pa-0">
				<p class="my-heading">Add Distributor</p>
			</v-col>
		</v-row>
		<v-row no-gutters dense align="stretch" class="d-flex">
			<v-card min-height="100%" min-width="100%" class="my-card d-flex">
				<v-row no-gutters dense class="d-flex flex-column">
					<v-form v-model="valid" ref="form" lazy-validation>
						<v-row no-gutters dense class="d-flex">
							<v-col cols="12">
								<p class="heading-sf20 pb-4">Distributor Information</p>
							</v-col>
							<v-col cols class="col-md-6 col-sm-12 px-2">
								<app-user-email
									v-model="distributor_information.email"
									label="Email"
									placeholder="Enter valid email address"
									type="user"
								></app-user-email>
							</v-col>
							<v-col cols class="col-md-6 col-sm-12 px-2">
								<app-phone-number
									v-model="distributor_information.phone"
									label="Phone"
									placeholder="Enter contact number"
									:rules="phoneRules"
								></app-phone-number>
							</v-col>
							<v-col cols class="col-md-6 col-sm-12 px-2">
								<app-text-box
									v-model="distributor_information.first_name"
									label="First Name"
									placeholder="First name"
									:rules="firstNameRules"
								></app-text-box>
							</v-col>
							<v-col cols class="col-md-6 col-sm-12 px-2">
								<app-text-box
									v-model="distributor_information.last_name"
									label="Last Name"
									placeholder="Last name"
									:rules="lastNameRules"
								></app-text-box>
							</v-col>
						</v-row>
						<v-row no-gutters dense class="d-flex">
							<v-col cols="12">
								<p class="heading-sf20 pb-4">Company Information</p>
							</v-col>
							<v-col cols class="col-md-6 col-sm-12 px-2">
								<app-user-email
									v-model="company_information.company_email"
									label="Email"
									placeholder="Enter valid email address"
									type="company"
								></app-user-email>
							</v-col>
							<v-col cols class="col-md-6 col-sm-12 px-2">
								<app-text-box
									v-model="company_information.company_name"
									label="Company Name"
									placeholder="Company name"
									:rules="companyNameRules"
								></app-text-box>
							</v-col>
							<v-col cols class="col-md-6 col-sm-12 px-2">
								<app-text-box
									v-model="company_information.main_contact"
									label="Main Contact"
									placeholder="Main Contact"
									:rules="mainContactRules"
								></app-text-box>
							</v-col>
							<v-col cols class="col-md-6 col-sm-12 px-2">
								<app-phone-number
									v-model="company_information.company_phone"
									label="Phone"
									placeholder="Enter contact number"
									:rules="phoneRules"
								></app-phone-number>
							</v-col>
							<v-col cols class="col-md-6 col-sm-12 px-2">
								<v-row no-gutters dense>
									<v-col cols="12" class="pb-0">
										<label>Address</label>
									</v-col>
									<v-col cols="12" class="pt-1">
										<v-autocomplete
											@keyup="findAddress($event)"
											@blur="selectAddress($event)"
											single-line
											outlined
											dense
											height="48"
											color="#CACEDA"
											type="text"
											append-icon
											:item-value="address"
											item-color="#F7F9FC"
											cache-items
											:items="googleAddress"
											return-object
											v-model="address"
											:rules="addressRules"
											placeholder="Address"
											required
										></v-autocomplete>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-form>
					<v-row no-gutters dense align="stretch" class="d-flex">
						<v-col
							v-if="error_message != ''"
							class="d-flex align-center mt-auto p-0 custom-error"
							cols="12"
						>{{ this.error_message }}</v-col>
						<v-col cols="12" class="d-flex justify-end mt-auto">
							<v-btn
								class="my-auto-btn my_elevation"
								id="my_elevation"
								@click="inviteDistributor()"
								:disabled="!valid"
								depressed
							>Invite Distributor</v-btn>
						</v-col>
					</v-row>
				</v-row>
			</v-card>
		</v-row>
	</div>
</template>

<script>
const TextBox = () => import("../../mycomponents/MyTextBox");
const PhoneNumber = () => import("../../mycomponents/MyPhoneNumber");
const UserEmail = () => import("../../mycomponents/UserEmail");
import {
	googlePlaces,
	inviteDistributor,
	emailCheck
} from "../../services/distributorservice";
export default {
	components: {
		AppTextBox: TextBox,
		AppPhoneNumber: PhoneNumber,
		AppUserEmail: UserEmail
	},
	data() {
		return {
			valid: true,
			error_message: "",
			distributor_information: {
				first_name: "",
				last_name: "",
				phone: "",
				email: ""
			},
			company_information: {
				company_name: "",
				company_email: "",
				main_contact: "",
				company_phone: "",
				company_address: "",
				session_token: "",
				place_id: ""
			},
			address: "",
			address_data: [],
			google_address: [],
			companyNameRules: [
				v => !!v || "Company name is required",
				v =>
					/^[A-Za-z0-9_ ]+$/.test(v) ||
					"Input alphabet character only"
			],
			addressRules: [v => !!v || "address is required"],
			mainContactRules: [
				v =>
					v && v.length > 0
						? /^[A-Za-z_ ]+$/.test(v) ||
						  "Input alphabet character only"
						: !v
			],
			firstNameRules: [
				v =>
					v && v.length > 0
						? /^[A-Za-z]+$/.test(v) ||
						  "Input alphabet character only"
						: !v
			],
			lastNameRules: [
				v =>
					v && v.length > 0
						? /^[A-Za-z]+$/.test(v) ||
						  "Input alphabet character only"
						: !v
			],
			phoneRules: [
				v =>
					v && v.length > 0
						? (v && v.length >= 14 && v.length <= 14) ||
						  "Phone number must be 10 digit"
						: !v
			]
		};
	},

	created() {
		this.$store.watch(
			state => {
				return state.auth.error;
			},
			() => {
				if (this.$router.currentRoute.path === "/distributor-invite") {
					this.error_message = this.$store.state.auth.error;
				}
			},
			{ deep: true }
		);
	},

	async mounted() {
		const uuidv4 = require("uuid/v4");
		this.company_information.session_token = uuidv4();
	},

	computed: {
		googleAddress() {
			return this.address_data;
		}
	},

	methods: {
		async findAddress(e) {
			let len = e.target.value.length / 3;
			if (Number.isInteger(len) && len != 0) {
				let data = {
					address: e.target.value,
					session_token: this.company_information.session_token
				};
				let address_data = await googlePlaces(data);

				address_data.dataset.forEach(item => {
					let ob = {
						address: item.description,
						place_id: item.place_id
					};
					this.address_data.push(item.description);
					this.google_address.push(ob);
				});
			}
		},

		selectAddress(e) {
			let add = e.target.value;
			this.company_information.company_address = add;
			this.google_address.forEach(item => {
				if (item.address === add) {
					this.company_information.place_id = item.place_id;
				}
			});
		},

		async inviteDistributor() {
			if (this.$refs.form.validate()) {
				this.$store.commit("errorMessage", "");
				this.error_message = "";

				let ob = {
					fullname:
						this.distributor_information.first_name +
						" " +
						this.distributor_information.last_name,
					email: this.distributor_information.email,
					phone: this.distributor_information.phone,
					company_name: this.company_information.company_name,
					company_email: this.company_information.company_email,
					main_contact: this.company_information.main_contact,
					company_phone: this.company_information.company_phone,
					company_address: this.company_information.company_address,
					place_id: this.company_information.place_id,
					session_token: this.company_information.session_token
				};

				let data = await inviteDistributor(ob);

				if (!data.data) {
					this.error_message = this.$store.state.auth.error;
				} else {
					let alert = {
						show: true,
						message: "Distributor invitation sent successfully",
						type: "success"
					};
					this.$store.commit("showAlert", alert);
					this.$refs.form.reset();
				}
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.distributor-invite {
	height: inherit;
}
</style>